<footer class="footer">
    <div class="footer-content row">
        <div class="left-content heading-3 col-12 col-md-7 mb-5 my-md-0 text-center">
            THE LEADING COMMERCIAL AND DEVELOPMENT REAL ESTATE PLATFORM
        </div>
        <div class="right-content col-12 col-md-5">
            <a class="footer-link" aria-label="Terms & Conditions" (click)="onLinkClick('Terms & Conditions')">
                <span class="footer-text">Terms & Conditions</span>
                <mat-icon class="footer-icon">gavel</mat-icon>
            </a>
            <a class="footer-link" aria-label="Privacy Policy" (click)="onLinkClick('Privacy Policy')">
                <span class="footer-text">Privacy Policy</span>
                <mat-icon class="footer-icon">privacy_tip</mat-icon>
            </a>
            <a class="footer-link" aria-label="Cookie Preferences" (click)="onLinkClick('Cookie Preferences')">
                <span class="footer-text">Cookie Preferences</span>
                <mat-icon class="footer-icon">cookie</mat-icon>
            </a>
            <a class="footer-link" aria-label="PAIA Manual" (click)="onLinkClick('PAIA Manual')">
                <span class="footer-text">PAIA Manual</span>
                <mat-icon class="footer-icon">description</mat-icon>
            </a>
            <!-- <a class="footer-link" aria-label="Legal Disclaimer" (click)="onLinkClick('Legal Disclaimer')">
                <span class="footer-text">Legal Disclaimer</span>
                <mat-icon class="footer-icon">info</mat-icon>
            </a> -->
        </div>
    </div>
</footer>